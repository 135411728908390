:root {
  --Pr-color: #3080a4;
  --Sec-color: #ffffff;
  --Tre-color: #f3ac40;
  --font-color: #07092a;
  --info-color: #26a7df;
  --bg-color: #ddd;
  --max-width: 1200px;
  --nth-child: #ededf1;
}

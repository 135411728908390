.journey_container {
  padding: 2rem 1rem;
}
.journey_grid {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px 1rem;
}

.journey_card {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: auto !important;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-radius: 15px;
  width: 100%;
  height: 400px;
  transition: width 1s ease;
  cursor: pointer;
}
.journey_card .imgBx {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--Pr-color);
  border-radius: 15px;
}
.journey_card .imgBx::before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: transparent;
  box-shadow: -10px 10px 0 var(--Sec-color);
}

.journey_card .imgBx::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 70px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: transparent;
  box-shadow: -10px 10px 0 var(--Sec-color);
}
.journey_card .imgBx img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.journey_card .content {
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px 20px;
  background-color: var(--info-color);
  border-radius: 15px;
  border-top-left-radius: 0;
}
.journey_card .content .price {
  position: absolute;
  top: -80px;
  left: 0;
  height: 80px;
  width: 50%;
  background-color: var(--info-color);
  border-top: 10px solid var(--Sec-color);
  border-right: 10px solid var(--Sec-color);
  border-top-right-radius: 25px;
}
.journey_card .content .price p {
  position: relative;
  padding: 10px 20px;
  margin: 15px auto;
  width: fit-content;
  display: block;
  background-color: var(--Sec-color);
  border-radius: 20px;
}
.journey_card .content .price::before {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  background-color: var(--info-color);
  border-radius: 50%;
  box-shadow: -10px -10px 0 var(--Sec-color);
}

.journey_card .content .price::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: -25px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: -10px 10px 0 var(--info-color);
}
.journey_card .content h2 {
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.journey_card .content p {
  text-align: center;
}

.journey_card .journey_card_image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--Pr-color);
  border-radius: inherit;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: width 1s ease;
}
.journey_card .journey_card_image:hover {
  width: 50%;
}
.journey_card .journey_card_image::before {
  position: absolute;
  content: "";
  width: 300px;
  height: 300px;
  background-color: var(--info-color);
  top: -20%;
  right: 30%;
  z-index: -1;
  border-radius: 50%;
}
.journey_card .journey_card_image img {
  width: 100%;
  /* height: 100%; */
  /* object-fit: cover; */
}
.journey_card .journey_card_info {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.journey_card:hover .journey_card_info {
  opacity: 1;
}

.journey_card_bg {
  padding: 2rem;
  background-color: var(--bg-color);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
.journey_card_bg span {
  display: inline-block;
  margin-bottom: 2rem;
  font-size: 1.75rem;
  color: var(--Pr-color);
}
.journey_card_bg h4 {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--font-color);
}
.journey_card_contnet {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: var(--Pr-color);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  transition: 0.3s;
}
.journey_card:hover .journey_card_contnet,
.journey_card:checked .journey_card_contnet {
  top: 0;
}
.journey_card_contnet span {
  display: inline-block;
  margin-bottom: 1rem;
  padding: 7px 9px;
  font-size: 1rem;
  color: var(--Sec-color);
  border: 2px solid var(--Sec-color);
  border-radius: 100%;
}
.journey_card_contnet h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--Sec-color);
}
.journey_card_contnet h4 {
  color: var(--Sec-color);
}

@media (width > 540px) {
  .journey_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.paginator {
  margin-top: 4rem;
}

.description_en {
  padding: 10px 20px;
  border: 2px solid var(--Pr-color);
  border-radius: 10px;
  margin-bottom: 20px;
  color: var(--font-color);
  cursor: default;
  text-transform: capitalize;
}
.loader {
  color: var(--Pr-color);
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

.section_container {
  padding: 2rem 1rem;
}
.contact_b_l {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.infor {
  position: relative;
  height: 100%;
  padding: 4rem 2rem;
  margin: 0 0 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.infor.right::before {
  right: 0;
}
.infor .item {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 0 var(--Pr-color);
  border-radius: 10px;
  white-space: nowrap;
}
.infor .item i {
  font-size: 40px;
  color: var(--Pr-color);
}
.infor .item label {
  opacity: 0.8;
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.contact_body .social_media {
  display: flex;
  align-items: center;
}

.contact_body .social_media a {
  margin-right: 20px;
  color: var(--Sec-color);
}
.contact_body .social_media i {
  color: var(--Sec-color);
}
.social_media {
  display: flex;
  gap: 20px;
}
.Btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  position: relative;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s;
  color: var(--Sec-color);
}
.svgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(4px);
  letter-spacing: 0.8px;
  border-radius: 10px;
  transition: all 0.3s;
  border: 1px solid rgba(156, 156, 156, 0.466);
}

.children1 .BG {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  z-index: -1;
  border-radius: 9px;
  pointer-events: none;
  transition: all 0.3s;
}
.children2 .BG {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  z-index: -1;
  border-radius: 9px;
  pointer-events: none;
  transition: all 0.3s;
  background: #3b5998;
  background: -moz-linear-gradient(
    45deg,
    #2f55a4 0%,
    #445c8f 25%,
    #c4a667 50%,
    #5874af 75%,
    #374a72 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #2f55a4 0%,
    #445c8f 25%,
    #c4a667 50%,
    #5874af 75%,
    #374a72 100%
  );
  background: linear-gradient(
    45deg,
    #2f55a4 0%,
    #445c8f 25%,
    #c4a667 50%,
    #5874af 75%,
    #374a72 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f55a4', endColorstr='#374a72',GradientType=1 );
}
.Btn:hover .BG {
  transform: rotate(35deg);
  transform-origin: bottom;
}

.Btn:hover .svgContainer {
  background-color: rgba(156, 156, 156, 0.466);
}

@media (max-width: 767px) {
  .infor {
    flex-direction: column;
    padding: 0;
    gap: 50px;
  }
  .infor .item {
    white-space: inherit;
    text-align: center;
  }
}

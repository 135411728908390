.destination_container {
  padding: 4rem 1rem;
}
.section_description {
  color: var(--font-color);
  opacity: 0.7;
  margin: 20px 0 30px;
  letter-spacing: 5px;
  font-size: 14px;
  max-width: unset;
}
.destination_grid {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem 1rem;
}
.destination_card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.destination_card img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: block;
  transition: transform 0.5s;
}
.destination_card:hover img {
  transform: scale(1.2);
}

.destination_card .overlay {
  height: 0px;
  width: 100%;
  background: linear-gradient(transparent, var(--info-color) 100%);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  transition: height 0.5s;
  color: var(--Sec-color);
}
.destination_card .overlay h2 {
  font-size: 50px;
  margin-bottom: 30px;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-weight: 800;
}
.destination_card .overlay p {
  font-weight: 500;
  color: var(---color);
}
.destination_card:hover .overlay {
  height: 100%;
}

/* .destination_card_det {
  padding: 1rem;
  text-align: center;
  gap: 1rem;
}

.destination_card_det h4 {
  color: var(--Tre-color);
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 600;
}
.destination_card_det p {
  letter-spacing: 2px;
  opacity: 0.7;
} */
@media (max-width: 767px) {
  .section_container {
    padding: 3rem 2rem;
  }
}
@media (width > 540px) {
  .destination_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

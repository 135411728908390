.Info_container {
  padding: 4rem 1rem;
}
.Info_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 4rem;
}
.Info_content .right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Info_content .right img {
  width: 400px;
  height: 400px;
  border-radius: 20px;
  object-fit: cover;
}
.Info_content .section_description {
  flex: 1;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 2;
  text-align: start;
}
@media (max-width: 767px) {
  .Info_content {
    flex-direction: column;
  }
  .Info_content .section_description {
    opacity: 1;
    letter-spacing: 0;
    line-height: 2;
  }
}

nav {
  position: fixed;
  isolation: isolate;
  top: 0;
  z-index: 100;
  width: 100%;
  /* padding: 0 6rem;
  height: 80px;
  z-index: 1000;
  */
  transition: 0.2s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}
nav.scrollClassName {
  background-color: var(--Sec-color);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(10px);
  transition: background-color 0.2s ease-in-out;
}
.nav_header {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--Sec-color);
}
.nav_header .nav_logo img {
  width: 15%;
}
.nav_header .nav_menu_btn i {
  font-size: 24px;
  cursor: pointer;
  color: var(--font-color);
}

.nav_links {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--Pr-color);
  transition: transform 0.5s;
  z-index: -1;
}

.nav_links.open {
  transform: translateY(100%);
}
.nav_links li a {
  font-weight: 600;
  color: var(--Sec-color);
  white-space: nowrap;
  cursor: pointer;
  text-transform: capitalize;
}
.nav_links li a.active {
  color: var(--Tre-color);
}
.nav_links li a:hover {
  color: var(--Tre-color);
}
.nav_links li:last-child .nav_btns {
  display: flex;
}
.nav_btns {
  display: none;
}

nav .p-component {
  padding: 8px 20px;
  gap: 10px;
  color: var(--Tre-color);
  border-color: var(--Tre-color);
}
nav .pi {
  color: var(--Tre-color);
}
nav .p-togglebutton.p-highlight .p-button {
  border-color: var(--info-color);
  background-color: var(--info-color);
  color: var(--Sec-color);
}
nav .p-togglebutton.p-highlight .p-button .p-button-icon {
  color: var(--Sec-color);
}

@media (width > 768px) {
  nav {
    position: fixed;
    padding: 1.5rem 4rem;
    /* max-width: 100%; */
    margin-inline: auto;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;
  }
  .nav_header {
    flex: 1;
    padding: 0;
    width: unset;
    background-color: transparent;
  }
  .nav_logo a img {
    width: 14% !important;
  }
  .nav_menu_btn {
    display: none;
  }
  .nav_links {
    flex: 1;
    position: static;
    width: fit-content;
    padding: 0;
    flex-direction: row;
    justify-content: flex-start;
    background-color: transparent;
    transform: none !important;
  }
  nav.scrollClassName .nav_links li a {
    color: var(--font-color) !important;
  }
  .nav_links li a {
    color: var(--Sec-color) !important;
    padding: 5px 10px;
    border-radius: 12px;
    text-transform: uppercase;
  }
  .nav_links li a.active {
    color: var(--Sec-color) !important;
    background-color: var(--info-color);
  }
  .nav_links a:hover {
    color: var(--Sec-color) !important;
    background-color: var(--info-color);
  }
  .nav_links li:last-child {
    display: none;
  }
  .nav_btns {
    display: flex;
    /* flex: 0.4; */
    justify-content: flex-end;
  }
}

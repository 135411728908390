@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "DM Sans", sans-serif;
  background-color: transparent;
}
html,
body {
  scroll-behavior: smooth;
}

.ar {
  flex-direction: row-reverse;
}
.arLang {
  font-family: "Cairo";
  font-size: 20px;
}

section {
  position: relative;
  /* top: 60px; */
  /* min-height: 100vh; */
}
.section_container {
  max-width: var(--max-width);
  margin: auto;
  padding: 5rem 1rem;
}
.section_header {
  margin-bottom: 5px;
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--font-color);
  text-align: center;
}
.section_description {
  max-width: 600px;
  margin-inline: auto;
  color: var(--Sec-color);
  text-align: center;
}

.btn {
  padding: 0.75rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  white-space: nowrap;
  color: var(--Sec-color);
  background-color: var(--Pr-color);
  border-radius: 5rem;
  transition: 0.3s;
  cursor: pointer;
}

img {
  display: flex;
  width: 100%;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.section_header {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--Pr-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section_header img {
  width: 8%;
  animation: move-from 3s ease-in-out infinite;
  animation-fill-mode: forwards;
}

@media (max-width: 767px) {
  .section_header {
    font-size: 2.2rem;
  }
  .section_description {
    font-size: 14px !important;
  }
  .section_header h2 {
    font-size: 3.5rem !important;
  }
  .section_container {
    padding: 1rem;
  }
  .section_header img {
    width: 20%;
  }
}

@keyframes move-from {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}

header {
  margin-top: 12rem;
  padding-inline: 1rem;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
header::before {
  position: absolute;
  content: "";
  height: 100%;
  width: calc(100% - 2rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-image: url("./assets/image/header-bg.jpg"); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  z-index: -1;
}
.header_container {
  display: grid;
}
.header_content {
  padding: 4rem 1rem;
}
.header_content p {
  margin-bottom: 5px;
  font-size: 30px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--Sec-color);
  text-align: center;
}
.header_content h1 {
  margin-bottom: 2rem;
  font-size: 4.5rem;
  font-weight: 600;
  color: var(--font-color);
  line-height: 5.5rem;
  text-align: center;
}
.header_btns {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (width > 768px) {
  header {
    margin-top: 6rem;
  }
  .header_container {
    grid-template-columns:
      minmax(0, 1fr)
      repeat(5, minmax(0, calc(var(--max-width) / 5)))
      minmax(0, 1fr);
  }
  .header_content {
    grid-column: 2/4;
    padding-block: 8rem;
  }
  .header_content :is(p, h1) {
    text-align: left;
  }
  .header_btns {
    justify-content: flex-start;
  }
  .header_image {
    grid-column: 4/8;
    position: relative;
    isolation: isolate;
    height: 100%;
  }
  .header_image img {
    position: absolute;
    top: 2rem;
    left: 0;
    height: 100%;
    width: unset;
    opacity: 1 !important;
  }
}
.p-paginator {
  justify-content: flex-end;
}
.header_image img {
  opacity: 1 !important;
  transform: translate(0%, 0%) !important;
}
/* From Uiverse.io by Allyhere */
.btn-donate {
  --clr-font-main: hsla(0 0% 20% / 100);
  /* --btn-bg-1: hsla(115 85% 65% / 1); */
  --btn-bg-1: hsla(225 2% 44% /1);
  --btn-bg-2: hsla(180 3% 56% / 1);
  --radii: 0.5em;
  cursor: pointer;
  padding: 0.9em 1.4em;
  min-width: 120px;
  min-height: 44px;
  font-size: var(--size, 1rem);
  font-weight: 500;
  transition: 0.8s;
  background-size: 280% auto;
  background-image: linear-gradient(
    325deg,
    var(--btn-bg-2) 0%,
    var(--btn-bg-1) 55%,
    var(--btn-bg-2) 90%
  );
  border: none;
  outline: none;
  border-radius: var(--radii);
  color: var(--Sec-color);
  box-shadow: 0px 0px 20px rgba(109, 110, 113, 0.5),
    0px 5px 5px -1px rgba(109, 100, 113, 0.25),
    inset 4px 4px 8px rgba(152, 153, 158, 0.5),
    inset -4px -4px 8px rgba(93, 97, 107, 0.35);
}

.btn-donate:hover {
  background-position: right top;
}

.btn-donate:is(:focus, :focus-visible, :active) {
  outline: none;
  box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
  cursor: pointer;
}
@media (prefers-reduced-motion: reduce) {
  .btn-donate {
    transition: linear;
  }
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100% !important;
}
.swiper-slide-prev {
  /* opacity: 0.5 !important; */
}
.swiper-3d .swiper-slide-shadow {
  background: transparent !important;
}

.slider {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* margin-top: 6rem; */
}

.slider .list .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider .list .item {
  position: relative;
  width: 100%;
  height: 100vh;
}
.slider .list .item .content {
  position: absolute;
  width: max-content;
  max-width: 50%;
  bottom: 5.5%;
  left: 5%;
  padding-right: 30%;
  box-sizing: border-box;
  color: var(--Sec-color);
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 12px;
  backdrop-filter: blur(40px);
  transition: background-color 0.2s ease-in-out;
}
.slider .list .item .content .title {
  font-size: 5rem;
  font-weight: bold;
  /* color: var(--Pr-color); */
}
.slider .list .item .content .desc {
  font-size: 16px;
}
.slider .thumbnail {
  display: flex;
  position: absolute;
  gap: 20px;
  bottom: 20px;
  right: 4%;
  width: 40% !important;
  border-radius: 12px;
  z-index: 10;
  box-sizing: border-box;
  padding: 10px 20px;
}
@media (max-width: 767px) {
  .slider .thumbnail {
    width: 70% !important;
    left: 35%;
    bottom: 0;
  }
  .slider.rtl .thumbnail {
    left: unset !important;
    right: 35% !important;
  }
  .slider .list .item .content {
    transform: translateX(-50%);
    bottom: unset;
    left: 35%;
    top: 12%;
    max-width: 60%;
  }
  .slider.rtl .list .item .content {
    left: 50% !important;
  }
  .slider .list .item .content .desc {
    font-size: 12px;
  }
}
.slider .thumbnail .item {
  width: 150px !important;
  height: 180px !important;
  flex-shrink: 0;
  position: relative;
}
.slider .thumbnail .item img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
}
.slider .swiper-slide {
  background-size: cover;
  background-position: center;
}

.slider .mySwiper2 {
  height: 100%;
  width: 100%;
}

.slider .mySwiper .swiper-slide {
  /* opacity: 0.7; */
  cursor: pointer;
}

.slider .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.slider .mySwiper .swiper-slide img,
.slider .mySwiper .swiper-slide-thumb-active img {
  border-radius: 12px;
}
.up {
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 30px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: var(--Sec-color);
  cursor: pointer;
  overflow: hidden;
}

.up i {
  color: var(--Pr-color);
  animation: moveUpDown 1s infinite;
}
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}
